import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

const defaultState = {
  isAuthenticated: false,
  setIsAuthenticated: () => {},
};

export const UserContext = React.createContext(defaultState);

const UserProvider = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Local Storage: getting data

  const syncAuthWithToken = useCallback(() => {
    const authToken = JSON.parse(localStorage.getItem("inplayer_token"));
    setIsAuthenticated(!!authToken?.token);
  }, [setIsAuthenticated]);

  useEffect(() => {
    syncAuthWithToken();
  }, [syncAuthWithToken]);

  return (
    <UserContext.Provider value={{ isAuthenticated, syncAuthWithToken }}>
      {props.children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
  labels: PropTypes.array,
};

export default UserProvider;
