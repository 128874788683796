import "@fontsource/noto-sans-jp/latin.css";
import "@fontsource/oswald/300.css";
import "@fontsource/oswald/500.css";
import "@fontsource/slabo-27px/400.css";
import * as React from "react";
import { PreviewStoreProvider } from "gatsby-source-prismic";
import UserProvider from "./src/contexts/userContext";

const setVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

export const onClientEntry = async () => {
  document.documentElement.style.setProperty(
    "--initialvh",
    `${window.innerHeight * 0.01}px`
  );
  setVH();
  window.addEventListener("resize", () => {
    setVH();
  });

  // Replicate gatsby-plugin-google-analytics behaviour
  // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js
  const pathIsExcluded =
    location &&
    typeof window.excludeGAPaths !== `undefined` &&
    window.excludeGAPaths.some(rx => rx.test(location.pathname));

  if (pathIsExcluded) return null;

  return null;
};

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>
    <UserProvider>{element}</UserProvider>
  </PreviewStoreProvider>
);
